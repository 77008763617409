<template>
  <div class="agent-stat-chart">
    <div class="columns is-marginless is-multiline is-mobile">
      <div class="column is-12">
        <b-field>
          <apart>
            <p>
              <span v-if="$moment().utcOffset() !== 0">
                Your timezone:
                <strong>GMT{{ $moment().format("Z") }}</strong>
              </span>
            </p>

            <b-select v-model="selectedDate" @input="getData">
              <option
                v-for="(option, index) in availableDates"
                :key="index"
                :value="option"
              >
                {{ option.label }}
              </option>
            </b-select>
          </apart>
        </b-field>
      </div>

      <div
        v-for="(option, index) in statistics"
        :key="`stat-${index}`"
        class="column is-6-mobile is-6-tablet is-6-desktop is-3-fullhd"
      >
        <p class="box stat has-background-light">
          <span
            class="is-size-6-mobile is-size-5 has-overflow-ellipsis-right"
            >{{ option.label }}</span
          >

          <span
            class="is-size-3-mobile is-size-4 title has-white-space-nowrap"
            >{{ $_.isNumber(option.value) ? option.value : `&hellip;` }}</span
          >
        </p>
      </div>

      <div class="column is-12-mobile is-12-tablet">
        <line-chart :chart-data="chartData" :options="chartOption" />
      </div>
    </div>
    <b-loading :is-full-page="false" :active.sync="isLoading" />
  </div>
</template>

<script>
import moment from "moment";
import { collection, doc, getDoc } from "@firebase/firestore";

const availableDates = [
  {
    key: "today",
    group: "day",
    label: "Today (GMT)",
    format: "YYYYMMDDHH",
    localFormat: "HH",
    value: moment.utc().format("YYYYMMDD")
  },
  {
    key: "yesterday",
    group: "day",
    label: "Yesterday (GMT)",
    format: "YYYYMMDDHH",
    localFormat: "HH",
    value: moment
      .utc()
      .add(-1, "days")
      .format("YYYYMMDD")
  },
  {
    key: "thismonth",
    group: "month",
    label: "This month (GMT)",
    value: moment.utc().format("YYYYMM")
  },
  {
    key: "lastmonth",
    group: "month",
    label: "Last month (GMT)",
    value: moment
      .utc()
      .add(-1, "month")
      .format("YYYYMM")
  },
  {
    key: "thisyear",
    group: "year",
    label: "This year",
    value: moment.utc().format("YYYY")
  },
  {
    key: "lastyear",
    group: "year",
    label: "Last year",
    value: moment
      .utc()
      .add(-1, "year")
      .format("YYYY")
  }
];

export default {
  name: "AgentStatsChart",
  props: {
    userId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      ref: collection(this.$firestore, `agents/${this.userId}/stats`),
      isLoading: false,
      availableDates: availableDates,
      selectedDate: availableDates[0],
      maxY: 0,
      statsChartConfig: {
        // Order of the keys here represents the order of the labels in the chart
        replies: {
          label: "Replies",
          borderColor: "rgb(23, 152, 232, 1)",
          backgroundColor: "rgb(23, 152, 232, 0.15)"
        },
        secretDecrypted: {
          label: "Secrets decrypted",
          borderColor: "rgb(236, 82, 70, 1)",
          backgroundColor: "rgb(236, 82, 70, 0.15)"
        },
        externalApiCall: {
          label: "Logins",
          borderColor: "rgb(251, 188, 4, 1)",
          backgroundColor: "rgb(251, 188, 4, 0.15)"
        },
        taskViewed: {
          label: "Tasks Viewed",
          borderColor: "rgb(153, 211, 169, 1)",
          backgroundColor: "rgb(153, 211, 169, 0.15)"
        }
      },
      stat: {}
    };
  },
  computed: {
    year() {
      return new Date().getFullYear();
    },
    monthNumber() {
      return new Date().getMonth() + 1;
    },
    chartOption() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        beginAtZero: true,
        scales: {
          yAxes: [
            {
              ticks: {
                max:
                  this.$_.isEmpty(this.stat) || this.maxY < 10
                    ? 10
                    : this.$_.round(this.maxY * 1.25)
              }
            }
          ]
        }
      };
    },
    chartData() {
      const datasets = this.$_.keys(this.statsChartConfig).map(key => {
        const data = this.generateRange(this.stat[key]);
        // eslint-disable-next-line
        this.maxY = this.$_.max([this.maxY, this.$_.max(data)]);
        return {
          borderWidth: 2,
          label: key,
          ...this.statsChartConfig[key],
          data
        };
      });
      return {
        labels: this.selectedRange,
        datasets
      };
    },
    selectedRange() {
      let rangeEndValue = 24;
      let rangeStartValue = 0;
      const mSelectedDate = this.$moment.utc(
        this.selectedDate.value,
        "YYYYMMDD"
      );

      if ("month" === this.selectedDate.group) {
        rangeStartValue = 1;
        rangeEndValue = mSelectedDate.daysInMonth() + 1;
      }

      if ("year" === this.selectedDate.group) {
        rangeStartValue = 1;
        rangeEndValue = 13;
      }

      return this.$_.range(rangeStartValue, rangeEndValue).map(i =>
        i < 10 ? `0${i}` : `${i}`
      );
    },
    statistics() {
      return this.$_.keys(this.statsChartConfig).map(key => {
        const data = this.stat[key];
        return {
          label: this.$_.get(this.statsChartConfig, `${key}.label`, key),
          value: this.$_.sum(this.$_.toArray(data))
        };
      });
    }
  },
  watch: {
    "selectedDate.key"(key) {
      localStorage.setItem("agent-stats-selected-date", key);
    }
  },
  created() {
    // Get last selected from local storage
    const dateFromLS = localStorage.getItem("agent-stats-selected-date");
    const matchedDate = availableDates.find(i => i.key === dateFromLS);
    this.selectedDate = matchedDate ? matchedDate : availableDates[0];

    this.getData();
  },
  methods: {
    getData() {
      this.isLoading = true;
      getDoc(doc(this.ref, this.selectedDate.value))
        .then(doc => {
          this.stat = this.$_.merge({}, doc.data());
          this.maxY = 0;
        })
        .catch(() => {
          this.$toast.open({
            message: "Error loading agent stats",
            type: "is-danger"
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    generateRange(data) {
      if (this.selectedDate.group === "day") {
        const copy = { ...data };
        data = {};
        this.$_(copy)
          .keys()
          .forEach(key => {
            const localTime = this.$moment
              .utc(`${this.selectedDate.value}${key}`, this.selectedDate.format)
              .format(this.selectedDate.localFormat);
            data[localTime] = copy[key];
          });
      }

      return this.selectedRange.map(i => {
        if (this.$_.has(data, i)) {
          return data[i];
        }
        return 0;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@sass/bulma/custom-variables";

.agent-stat-chart {
  position: relative;

  .stat {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;

    @include mobile {
      flex-wrap: wrap;

      span {
        width: 100%;
      }
    }
  }
}
</style>
